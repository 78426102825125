<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
            :src="`${$store.state.common.publicPath}img/avatars/6.png`"
            class="c-avatar-img"
          />
        </div>
      </CHeaderNavLink>
    </template>

    <!-- <CDropdownItem>
      <CIcon name="cil-user" />Profile
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-settings" />Settings
    </CDropdownItem> -->

    <CDropdownItem @click="handleLogout">
      <CIcon name="cil-lock-locked" />Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: "TheHeaderDropdownAccnt",
  data() {
    return {
      itemsCount: 42,
    };
  },
  methods: {
    async handleLogout() {
      await this.$store.dispatch("user/logout");
      this.$router.push(`/`);
    },
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
