export default [
    {
        _name: "CSidebarNav",
        _children: [
            {
                _name: "CSidebarNavItem",
                name: "Users",
                to: "/admin/users",
                icon: "cil-user",
            },
            {
                _name: "CSidebarNavItem",
                name: "Contract Lengths",
                to: "/admin/contract-lengths",
                icon: "cil-calendar",
            },
            
            {
                _name: "CSidebarNavItem",
                name: "Amenities",
                to: "/admin/amenities",
                icon: "cil-wifi-signal-0",
            },
            {
                _name: "CSidebarNavItem",
                name: "Additional Options",
                to: "/admin/additionalOptions",
                icon: "cil-options",
            },
            {
                _name: "CSidebarNavItem",
                name: "Layout Options",
                to: "/admin/layout",
                icon: "cil-layers",
            },
            {
                _name: "CSidebarNavItem",
                name: "Nearby Amenities",
                to: "/admin/nearby-amenities",
                icon: "cil-hospital",
            },
            {
                _name: "CSidebarNavItem",
                name: "Booked Contracts",
                to: "/admin/contract",
                icon: "cil-notes",
            },
            {
                _name: "CSidebarNavItem",
                name: "Properties",
                to: "/admin/properties",
                icon: "cil-building",
            },
            {
                _name: "CSidebarNavItem",
                name: "Contact Us",
                to: "/admin/contect-us",
                icon: "cil-phone",
            },
            {
                _name: "CSidebarNavItem",
                name: "Offers",
                to: "/admin/offers",
                icon: "cil-dollar",
            },
        ],
    },
];
