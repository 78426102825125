<template>
  <CHeader fixed with-subheader light>
    <CToggler in-header class="ml-3 d-lg-none" v-c-emit-root-event:toggle-sidebar-mobile />
    <CToggler in-header class="ml-3 d-md-down-none" v-c-emit-root-event:toggle-sidebar />
    <CHeaderBrand
      class="mx-auto d-lg-none"
      :src="`${$store.state.common.publicPath}img/brand/coreui-vue-logo.png`"
      width="220"
      height="20"
      alt="CoreUI Logo"
    />
    <CHeaderNav class="d-md-down-none mr-auto">
      <!-- <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/admin/dashboard">Dashboard</CHeaderNavLink>
      </CHeaderNavItem> -->
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <CHeaderNavItem class="d-md-down-none mx-2">
        <!-- <CHeaderNavLink>
          <CIcon name="cil-bell" />
        </CHeaderNavLink> -->
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <!-- <CHeaderNavLink>
          <CIcon name="cil-list" />
        </CHeaderNavLink> -->
      </CHeaderNavItem>

      <TheHeaderDropdownAccnt />
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt
  }
};
</script>
